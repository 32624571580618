import {
  ChannelType,
  CreateCheckoutFromCurrentCartRequest,
  CreateCheckoutResponse,
} from '@wix/ambassador-ecom-v1-cart/types';
import {
  CreateOrderRequest,
  CreateOrderResponse,
} from '@wix/ambassador-checkout/types';
import { CartFlow, ReservedPaymentOptionIds } from '../../../../types/types';
import { CreateActionParams } from '../actions';
import { bookingsUouSessionAddedToCartModalInteractions } from '@wix/bi-logger-wixboost-ugc/v2';
import { CartModalInteractionActionName } from '../../../../types/biLoggerTypes';
import { SlotService } from '../../../../utils/state/types';
import { ExperimentsConsts } from '../../../../consts/experiments';

export type OnCartCTAClick = (
  service: SlotService,
  cartFlow: CartFlow,
) => Promise<void>;

export function createOnCartCTAClickAction({
  context,
  getControllerState,
}: CreateActionParams): OnCartCTAClick {
  return async (service: SlotService, cartFlow: CartFlow) => {
    const { wixSdkAdapter, formApi, biLogger, experiments } = context;
    const [state] = getControllerState();
    const { paymentDetails, selectedPaymentOption, memberships } = service;
    const { cartModal } = state;

    const isAdditionalTimeSlotsInFormPluginEnabled = experiments.enabled(
      ExperimentsConsts.AdditionalTimeSlotsInFormPlugin,
    );

    if (
      cartFlow === CartFlow.ADD_MORE_SESSIONS ||
      (!isAdditionalTimeSlotsInFormPluginEnabled &&
        cartFlow === CartFlow.CLOSE_MODAL)
    ) {
      biLogger?.report(
        bookingsUouSessionAddedToCartModalInteractions({
          ...cartModal?.biLoggerData,
          actionName: isAdditionalTimeSlotsInFormPluginEnabled
            ? CartModalInteractionActionName.AddMoreSessions
            : cartFlow === CartFlow.ADD_MORE_SESSIONS
            ? CartModalInteractionActionName.AddMoreSessions
            : CartModalInteractionActionName.Close,
        }),
      );

      await wixSdkAdapter.navigateAfterAddToCart();
    } else if (
      cartFlow === CartFlow.CHECKOUT ||
      cartFlow === CartFlow.CLOSE_MODAL_OVERLAY ||
      (isAdditionalTimeSlotsInFormPluginEnabled &&
        cartFlow === CartFlow.CLOSE_MODAL)
    ) {
      let cartModalBiAction = CartModalInteractionActionName.GoToCheckout;

      if (cartFlow === CartFlow.CLOSE_MODAL) {
        cartModalBiAction = CartModalInteractionActionName.Close;
      } else if (cartFlow === CartFlow.CLOSE_MODAL_OVERLAY) {
        cartModalBiAction = CartModalInteractionActionName.OverlayClose;
      }

      biLogger?.report(
        bookingsUouSessionAddedToCartModalInteractions({
          ...cartModal?.biLoggerData,
          actionName: isAdditionalTimeSlotsInFormPluginEnabled
            ? cartModalBiAction
            : CartModalInteractionActionName.GoToCheckout,
        }),
      );
      const isSingleItemOnCart = cartModal?.lineItems?.length === 1;
      const isMembershipOptionSelected = memberships?.eligibleMemberships?.find(
        (membership) => selectedPaymentOption.id === membership.id,
      );
      const isCustomPrice =
        paymentDetails.priceText &&
        selectedPaymentOption.id === ReservedPaymentOptionIds.SingleSession;
      const isFreePrice =
        paymentDetails.isFree &&
        selectedPaymentOption.id === ReservedPaymentOptionIds.SingleSession;
      const shouldGoToTYP =
        isMembershipOptionSelected || isCustomPrice || isFreePrice;
      if (isSingleItemOnCart) {
        try {
          const createCheckoutFromCurrentCartRequest: CreateCheckoutFromCurrentCartRequest =
            {
              channelType: ChannelType.WEB,
            };
          const checkoutFromCartResponse: CreateCheckoutResponse =
            await formApi.createCheckoutFromCurrentCart(
              createCheckoutFromCurrentCartRequest,
            );
          if (!checkoutFromCartResponse.checkoutId || !shouldGoToTYP) {
            await wixSdkAdapter.navigateToEcomCart();
          }
          if (shouldGoToTYP) {
            const createOrderRequest: CreateOrderRequest = {
              id: checkoutFromCartResponse.checkoutId,
            };
            const orderFromCheckoutResponse: CreateOrderResponse =
              await formApi.createOrderFromCheckout(createOrderRequest);
            if (orderFromCheckoutResponse.orderId) {
              await wixSdkAdapter.navigateToEcomThankYouPage({
                orderId: orderFromCheckoutResponse.orderId,
              });
            }
          }
        } catch (e) {
          await wixSdkAdapter.navigateAfterAddToCart();
        }
      } else {
        await wixSdkAdapter.navigateToEcomCart();
      }
    } else {
      throw new Error('CartFlow cannot be undefined after adding to cart');
    }
  };
}
