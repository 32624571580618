import { BenefitWithPlanInfo } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import settingsParams from '../../../components/BookingsForm/settingsParams';
import { BusinessInfo, PlanWithDescription } from '../../../types/types';
import { ControllerFlowAPI, Experiments } from '@wix/yoshi-flow-editor';
import {
  BookingRequestKeyMappings,
  getFieldFromSchema,
} from '../../mappers/form-submission.mapper';
import { FormView } from '@wix/forms-ui/types';
import { ExperimentsConsts } from '../../../consts/experiments';
import { SelectedPaymentOption } from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { IFlowApiAdapter } from '../../flow-api-adapter/types';

export const getServicePlans = (
  serviceId: string,
  plans?: PlanWithDescription[],
  benefitsWithPlanInfo?: BenefitWithPlanInfo[],
): PlanWithDescription[] => {
  if (!plans || !benefitsWithPlanInfo || !benefitsWithPlanInfo.length) {
    return [];
  }

  const servicePlans = benefitsWithPlanInfo
    .filter((b) => b.benefit?.resourceIds?.includes(serviceId))
    .map((b) => b.planInfo?.id);

  return plans.filter((p) => servicePlans.includes(p.id));
};

export const getDefaultPaymentType = (
  settings: ControllerFlowAPI['settings'],
  paymentTypes: SelectedPaymentOption[],
): SelectedPaymentOption => {
  const defaultPaymentType = settings.get(settingsParams.defaultPaymentTime);
  if (paymentTypes.includes(defaultPaymentType)) {
    return defaultPaymentType;
  }

  if (paymentTypes.length === 1) {
    return paymentTypes[0];
  }

  return paymentTypes.filter(
    (paymentType) => paymentType !== SelectedPaymentOption.MEMBERSHIP,
  )[0];
};

export const getNumberOfParticipantsValue = (formSchema: FormView): number => {
  const numberOfParticipants = getFieldFromSchema(
    formSchema,
    BookingRequestKeyMappings.NO_OF_PARTICIPANTS,
  )?.renderInfo?.displayProperties?.defaultValue;
  return (numberOfParticipants && Number(numberOfParticipants)) || 1;
};

export const getEmailValue = (formSchema: FormView): string => {
  return getFieldFromSchema(formSchema, BookingRequestKeyMappings.EMAIL)
    ?.renderInfo?.displayProperties?.defaultValue;
};
export async function isCartEnabled({
  experiments,
  wixSdkAdapter,
  businessInfo,
}: {
  experiments: Experiments;
  wixSdkAdapter: IFlowApiAdapter;
  businessInfo: BusinessInfo;
}) {
  const isCartSpecEnabled = experiments.enabled(ExperimentsConsts.Cart);

  return (
    (isCartSpecEnabled && (await wixSdkAdapter.isCartEnabled(businessInfo))) ||
    false
  );
}
