import {
  IAppData,
  IPlatformAPI,
  IWixAPI,
} from '@wix/native-components-infra/dist/es/src/types/types';
import {
  PRICING_PLAN_APP_DEF_ID,
  PRICING_PLAN_SECTION_ID,
  BOOKINGS_APP_DEF_ID,
  BOOKINGS_CHECKOUT_SECTION_ID,
  BOOKINGS_LIST_SECTION_ID,
  BOOKINGS_SCHEDULER_SECTION_ID,
  ECOM_APP_DEF_ID,
  ECOM_CART_SECTION_ID,
  DEFAULT_MODAL_DIMENSIONS,
  PREVIEW_MODAL_DIMENSIONS,
} from './consts';
import { Referrals, BookingsQueryParams, IFlowApiAdapter } from './types';
import { ServiceType, BusinessInfo } from '@wix/bookings-uou-types';
import { Booking } from '@wix/ambassador-bookings-server/types';
import { getPricingPlansPublicAPI } from '@wix/pricing-plans-tpa-api';
import type { PricingPlansPublicAPI } from '@wix/pricing-plans-tpa-api';
import type Experiments from '@wix/wix-experiments';

declare const WorkerGlobalScope: any;

export class FlowApiAdapter implements IFlowApiAdapter {
  private currentLanguage;
  private pricingPlansApi: PricingPlansPublicAPI | undefined;

  constructor(
    private readonly wixCodeApi: IWixAPI,
    private readonly platformAPIs: IPlatformAPI,
    private readonly appParams: IAppData,
    private readonly experiments: Experiments,
  ) {
    this.currentLanguage = this.getCurrentLanguageFromPlatform();
  }

  private getCurrentLanguageFromPlatform() {
    return this.getCurrentLanguageFromLocale(this.wixCodeApi.site.language);
  }

  private getCurrentLanguageFromLocale(locale?: string) {
    const localeToSubstring = locale ? locale.substring(0, 2) : undefined;
    return localeToSubstring || 'en';
  }

  async openUoUPremiumModal(
    serviceType: ServiceType,
    referralInfo?: string,
    isDayful?: boolean,
  ) {
    const modalType = 'uou_not_bookable_modal';
    return this.openModal(
      modalType,
      serviceType,
      DEFAULT_MODAL_DIMENSIONS,
      referralInfo,
      isDayful,
    );
  }

  async openModal(
    modalType: string,
    serviceType: ServiceType,
    dimensions: {
      width: number | string;
      height: number | string;
    },
    referralInfo?: string,
    isDayful?: boolean,
  ) {
    const baseUrl = 'https://bookings.wixapps.net/_api/bookings-viewer';
    const modalUrl = `${baseUrl}/viewer-modals`;
    const referralInfoParam = referralInfo
      ? `&referralInfo=${referralInfo}`
      : ``;
    return this.wixCodeApi.window.openModal(
      `${modalUrl}?deviceType=${this.getDeviceType()}&instance=${this.getInstance()}&locale=${
        this.wixCodeApi.site.language
      }&modal=${modalType}&serviceType=${serviceType}${referralInfoParam}${
        isDayful ? '&dayful=sch' : ''
      }`,
      { ...dimensions, theme: 'BARE' },
    );
  }

  getInstance() {
    return (
      (this.wixCodeApi.site.getAppToken
        ? this.wixCodeApi.site.getAppToken(BOOKINGS_APP_DEF_ID)
        : undefined) || this.appParams.instance
    );
  }

  getInstanceForEcom() {
    return this.wixCodeApi.site.getAppToken
      ? this.wixCodeApi.site.getAppToken(ECOM_APP_DEF_ID)
      : undefined;
  }

  getDeviceType() {
    return this.wixCodeApi.window.formFactor;
  }

  async openEcomCheckoutPreviewModal() {
    const modalUrl = `https://ecom.wix.com/storefront/cartModal?instance=${this.getInstanceForEcom()}&modal=notInLiveSite`;
    return this.wixCodeApi.window.openModal(modalUrl, {
      ...PREVIEW_MODAL_DIMENSIONS,
      theme: 'BARE',
    });
  }

  async openEcomCheckoutTemplateModal() {
    const modalUrl = `https://ecom.wix.com/storefront/cartModal?instance=${this.getInstanceForEcom()}&modal=noOnlinePayments`;
    return this.wixCodeApi.window.openModal(modalUrl, {
      ...PREVIEW_MODAL_DIMENSIONS,
      theme: 'BARE',
    });
  }

  async promptUserLogin() {
    return this.wixCodeApi.user.promptLogin({
      mode: 'login',
      lang: this.currentLanguage,
    });
  }

  onUserLogin(callback: any) {
    this.wixCodeApi.user.onLogin(callback);
  }

  isPricingPlanInstalled(): Promise<boolean> {
    if (this.wixCodeApi.site) {
      if (this.wixCodeApi.site.isAppSectionInstalled) {
        return Promise.resolve(
          this.wixCodeApi.site.isAppSectionInstalled({
            appDefinitionId: PRICING_PLAN_APP_DEF_ID,
            sectionId: PRICING_PLAN_SECTION_ID,
          }),
        );
      }
      // TODO: remove when wixCodeApi.site.getAppToken is added to viewerWrapper
      if (this.wixCodeApi.site.getAppToken) {
        return Promise.resolve(
          !!this.wixCodeApi.site.getAppToken(PRICING_PLAN_APP_DEF_ID),
        );
      }
    }
    return this.isPricingPlanInstalledLegacyMode();
  }

  private isPricingPlanInstalledLegacyMode(): Promise<boolean> {
    return new Promise((resolve) => {
      if (window.Wix.Utils.getViewMode() !== 'standalone') {
        window.Wix.isAppSectionInstalled(
          PRICING_PLAN_SECTION_ID,
          { appDefinitionId: PRICING_PLAN_APP_DEF_ID },
          (isInstalled) => {
            resolve(isInstalled);
          },
        );
      } else {
        resolve(false);
      }
    });
  }

  async navigateAfterAddToCart(viewCart?: boolean) {
    let continueShoppingUrl = this.getUrlQueryParamValue(
      BookingsQueryParams.CONTINUE_SHOPPING_URL,
    );
    if (!continueShoppingUrl) {
      if (viewCart) {
        return this.navigateToEcomCart();
      } else {
        return this.navigateToHome();
      }
    }
    continueShoppingUrl = decodeURIComponent(continueShoppingUrl);

    const link = new URL(continueShoppingUrl, this.wixCodeApi.location.baseUrl);
    link.searchParams.append(
      BookingsQueryParams.REFERRAL,
      Referrals.BOOKINGS_FORM_WIDGET_WITH_CART,
    );

    this.wixCodeApi.location.to!(
      `${link.pathname}?${link.searchParams.toString()}`,
    );
  }

  async getPricingPlansApi() {
    if (!this.pricingPlansApi) {
      this.pricingPlansApi = await getPricingPlansPublicAPI(this.wixCodeApi);
    }
    return this.pricingPlansApi;
  }

  async navigateToHome() {
    return this.wixCodeApi.location.to!(this.wixCodeApi.location.baseUrl);
  }

  async navigateToPricingPlan({
    redirectTo,
    planIds,
    maxStartDate,
    queryParams,
    title,
    subtitle,
  }: {
    redirectTo: {
      sectionId: string;
      relativePath: string;
    };
    planIds?: string[];
    maxStartDate?: string;
    queryParams?: {};
    title?: string;
    subtitle?: string;
  }): Promise<void> {
    const api = await this.getPricingPlansApi();
    return api.navigateToPackagePicker({
      planIds,
      title,
      subtitle,
      checkout: {
        maxStartDate,
        successStatus: {
          navigation: {
            type: 'section',
            options: {
              appDefinitionId: BOOKINGS_APP_DEF_ID,
              sectionId: redirectTo.sectionId,
              state: redirectTo.relativePath,
              queryParams,
            },
          },
        },
      },
    });
  }

  getViewMode() {
    return this.wixCodeApi.window.viewMode.toLowerCase();
  }

  isPreviewMode() {
    return this.getViewMode() === 'preview';
  }

  isEditorMode() {
    return this.getViewMode() === 'editor';
  }

  isSiteMode() {
    return this.getViewMode() === 'site';
  }

  isTemplateMode() {
    return this.getDecodedInstance().siteIsTemplate;
  }

  private getDecodedInstance() {
    const instance = this.getInstance();
    if (!instance) {
      return null;
    }
    const encodedInstance = instance.substring(instance.indexOf('.') + 1);
    return JSON.parse(this.decodeBase64(encodedInstance));
  }

  private decodeBase64(str: string) {
    if (typeof atob !== 'undefined') {
      return atob(str);
    }
    return '{}';
  }

  getMetaSiteId() {
    return this.platformAPIs.bi!.metaSiteId;
  }

  getInstanceId() {
    return this.appParams.instanceId;
  }

  getAppDefId() {
    return this.appParams.appDefinitionId;
  }

  getServerBaseUrl() {
    return this.isSSR() && this.appParams.baseUrls
      ? this.appParams.baseUrls.serverBaseUrl
      : '/';
  }

  isOwner() {
    return (
      this.getOwnerId() === this.getVisitorId() ||
      this.getOwnerId() === this.getMemberId()
    );
  }

  getVisitorId() {
    return this.platformAPIs.bi!.visitorId;
  }
  getOwnerId() {
    return this.platformAPIs.bi!.ownerId;
  }

  getMemberId() {
    return this.wixCodeApi.user.currentUser.id;
  }

  getSiteRevision() {
    return this.wixCodeApi.site.revision;
  }

  async navigateToBookingsCheckout(
    bookedEntity: Booking,
    referral?: string,
    timezone?: string,
  ) {
    const encodedBooking = this.encodeUTF8ToUrlSafeBase64(
      JSON.stringify(bookedEntity),
    );
    const suffix = `/checkout/${encodedBooking}`;

    const queryParams = {
      referral,
      timezone,
    };

    return this.navigateToBookingsWithSuffix(
      `${suffix}${this.queryBuilder(queryParams)}`,
    );
  }

  async navigateToBookingsWithSuffix(suffix = '') {
    const bookingsRelativeUrl = await this.getBookingsRelativeUrl();
    this.wixCodeApi.location.to!(bookingsRelativeUrl + suffix);
  }

  isBookCheckoutInstalled(): Promise<boolean> {
    return this.isBookingsSectionInstalled(BOOKINGS_CHECKOUT_SECTION_ID);
  }

  isRunningInIframe(): boolean {
    return !(
      typeof WorkerGlobalScope !== 'undefined' &&
      self instanceof WorkerGlobalScope
    );
  }

  private isBookingsSectionInstalled(sectionId: string): Promise<boolean> {
    if (this.isRunningInIframe()) {
      return new Promise((resolve) => {
        window.Wix.isAppSectionInstalled(
          sectionId,
          { appDefinitionId: BOOKINGS_APP_DEF_ID },
          (isInstalled) => {
            resolve(isInstalled);
          },
        );
      });
    }
    return Promise.resolve(
      this.wixCodeApi.site.isAppSectionInstalled({
        sectionId,
        appDefinitionId: BOOKINGS_APP_DEF_ID,
      }),
    );
  }

  async getBookingsRelativeUrl(): Promise<string> {
    const isBookingCheckoutInstalled = await this.isBookCheckoutInstalled();
    const fullBookingsUrl = (
      await this.wixCodeApi.site.getSectionUrl({
        sectionId: isBookingCheckoutInstalled
          ? BOOKINGS_CHECKOUT_SECTION_ID
          : BOOKINGS_SCHEDULER_SECTION_ID,
        appDefinitionId: BOOKINGS_APP_DEF_ID,
      })
    ).url;
    const baseUrl = this.wixCodeApi.location.baseUrl;
    if (baseUrl) {
      const urlParts = fullBookingsUrl!.split(baseUrl);
      return urlParts.length > 1 ? urlParts[1] : fullBookingsUrl!;
    }
    return fullBookingsUrl!;
  }

  private removeUndefinedKeys(obj: any) {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v !== undefined),
    );
  }

  private queryBuilder(queryParams: Object, copyCurrentQueryParams = true) {
    const currentParams = copyCurrentQueryParams
      ? this.getUrlQueryParams()
      : {};
    const params = [];

    for (const [key, value] of Object.entries({
      ...currentParams,
      ...this.removeUndefinedKeys(queryParams),
    })) {
      if (value) {
        params.push([`${key}=${value}`]);
      }
    }
    return params.length ? `?${params.join('&')}` : '';
  }

  private encodeUTF8ToUrlSafeBase64(str: string) {
    return btoa(unescape(encodeURIComponent(str))).replace(/\//g, '-');
  }

  isSSR() {
    return this.wixCodeApi.window.rendering.env === 'backend';
  }

  async navigateToEcomCheckoutPage({
    checkoutId,
    continueShoppingUrl,
  }: {
    checkoutId: string;
    continueShoppingUrl?: string;
  }): Promise<void> {
    const ecomApi = await this.wixCodeApi.site.getPublicAPI(ECOM_APP_DEF_ID);
    return ecomApi.navigate.toCheckout({
      checkoutId,
      ...(continueShoppingUrl ? { continueShoppingUrl } : {}),
    });
  }

  async navigateToEcomThankYouPage({
    orderId,
    continueShoppingUrl,
  }: {
    orderId: string;
    continueShoppingUrl?: string;
  }): Promise<void> {
    const ecomApi = await this.wixCodeApi.site.getPublicAPI(ECOM_APP_DEF_ID);
    return ecomApi.navigate.toThankYouPage({
      orderId,
      ...(continueShoppingUrl ? { continueShoppingUrl } : {}),
    });
  }

  async reloadCart() {
    const ecomApi = await this.wixCodeApi.site.getPublicAPI(ECOM_APP_DEF_ID);
    await ecomApi.cart.reloadCart();
  }

  async navigateToEcomCart(): Promise<void> {
    const ecomApi = await this.wixCodeApi.site.getPublicAPI(ECOM_APP_DEF_ID);
    return ecomApi.navigate.toCart();
  }

  async isCartEnabled(businessInfo: BusinessInfo) {
    if (businessInfo.isCartEnabled) {
      return this.isCartInstalled();
    }
    return false;
  }

  private isCartInstalled() {
    return this.wixCodeApi.site.isAppSectionInstalled({
      sectionId: ECOM_CART_SECTION_ID,
      appDefinitionId: ECOM_APP_DEF_ID,
    });
  }

  getCsrfToken() {
    return this.platformAPIs.getCsrfToken();
  }

  getUrlQueryParams(): { [key: string]: string } {
    return this.wixCodeApi.location.query;
  }

  getUrlQueryParamValue(key: BookingsQueryParams): string {
    const params = {
      ...this.getSectionUrlParams(),
      ...this.getUrlQueryParams(),
    };
    return params[key];
  }

  getSectionUrlParams(): { [key: string]: string } {
    try {
      const params = {
        ...(this.getUrlQueryParams().appSectionParams
          ? JSON.parse(decodeURI(this.getUrlQueryParams().appSectionParams))
          : {}),
      };
      return params;
    } catch {
      return {};
    }
  }

  isBookOnlineInstalled(): Promise<boolean> {
    return this.isBookingsSectionInstalled(BOOKINGS_LIST_SECTION_ID);
  }

  async getBookOnlineFullUrl() {
    const isBookOnlineInstalled = await this.isBookOnlineInstalled();
    const bookOnlineFullUrl = (
      await this.wixCodeApi.site.getSectionUrl({
        sectionId: isBookOnlineInstalled
          ? BOOKINGS_LIST_SECTION_ID
          : BOOKINGS_SCHEDULER_SECTION_ID,
        appDefinitionId: BOOKINGS_APP_DEF_ID,
      })
    ).url;
    return bookOnlineFullUrl!;
  }

  setRobotsNoIndex = () => {
    const METATAG_NAME_ROBOTS = 'robots';
    const METATAG_CONTENT_NOINDEX = 'noindex';

    const metaTags = this.wixCodeApi.seo.metaTags || [];
    const robotsIndex = metaTags.findIndex(
      (tag) => tag?.name === METATAG_NAME_ROBOTS,
    );
    if (robotsIndex > -1) {
      metaTags[robotsIndex].content = METATAG_CONTENT_NOINDEX;
    } else {
      // @ts-expect-error
      metaTags.push({
        name: METATAG_NAME_ROBOTS,
        content: METATAG_CONTENT_NOINDEX,
      });
    }
    this.wixCodeApi.seo.setMetaTags?.(metaTags);
  };

  getFromSessionStorage(key: string) {
    return this.platformAPIs.storage.session.getItem(key)!;
  }
}
